import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RightNavBar from './RightNavBar';
import TopBar from './TopBar';
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
   // display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 64,

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
      transitionDuration: 500,
     
    }
  },
  wrapper1: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 64,
    transitionDuration: 500,

    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 70,
      transitionDuration: 500,
    }
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'auto',

  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { open, setOpen } = useDashboardContext();
  console.log(open)

  return (
    <div className={classes.root}>
      <TopBar 
      
      open={open}
      setOpen={setOpen}
      onMobileNavOpen={() => setMobileNavOpen(true)} />
      {/* <RightNavBar
         onMobileClose={() => setMobileNavOpen(false)}
         openMobile={isMobileNavOpen}
      /> */}
      <div className={open ? classes.wrapper : classes.wrapper1}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
