import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ImportForCompanySetupContext = createContext({
  ...initialCreateState,
  admitStudent: () => Promise.resolve(),
  addSection: () => Promise.resolve(),
  importStudents:()=>Promise.resolve(),
  importPayRecords:()=>Promise.resolve(),
  importStudentsAddress:()=>Promise.resolve(),
  importStudentsMarksCard:()=>Promise.resolve(),
  importStudentsFormData:()=>Promise.resolve(),

});

export const ImportCompanySetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const admitStudent = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_import_students',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_import_students',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };
  const addSection = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_student_section',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_student_section',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };

  const importStudents = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_import_students',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_import_students',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };
  const importPayRecords = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_pay_records',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_pay_records',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };
  const importStudentsAddress = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/address_card_upload',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/address_card_upload',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };

  const importStudentsMarksCard = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/marks_card_upload',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/marks_card_upload',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };


  const importStudentsFormData = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_student_form_data',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_student_form_data',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };

  return (
    <ImportForCompanySetupContext.Provider
      value={{
        ...state,
        admitStudent,
        addSection,
        importStudents,
        importPayRecords,
        importStudentsAddress,
        importStudentsMarksCard,
        importStudentsFormData
      }}
    >
      {children}
    </ImportForCompanySetupContext.Provider>
  );
};

export default ImportForCompanySetupContext;
