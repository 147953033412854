import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, makeStyles,CircularProgress,SvgIcon,List,  Divider, Button,Typography,  withStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import useAuth from 'src/hooks/useAuth';
import gql from 'graphql-tag';
import client from 'src/utils/GQLClient';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import Header from './Header'
import LabelItem from './NavItem';
import PropTypes from 'prop-types';
import "./App.css";
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor:'#FFFF',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    
    //paddingBottom: theme.spacing(3)
  },
  divider: {
    width: 1,

    position : 'fixed',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
    paddingLeft : 256,
    
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'hidden'
  },
 
  sticky : {
    position: 'fixed',
    top: 0 ,
    width: '100%'
  },
  sticky : {

    position: "fixed",
    top: 64,
    left: 256,
    right: 0,
    zIndex: 1000,
    backgroundColor: "#fff",

    },
    sticky1 : {

      position: "fixed",
      top: 64,
      left: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: "#fff",

    }
}));

const GreenButton = withStyles({
  root: {
    backgroundColor:'#33691e',
    color : 'white',
    '&:hover': {
      backgroundColor: '#33691e',
    },
   
  }
})(Button);

const Applications = ({children}) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { institution } = useAuth();
  const { user } = useAuth();


  // const fixedText = "Applications";
  // const whenNotFixed = "Applications";
  // const [headerText, setHeaderText] = useState(whenNotFixed);
  // useEffect(() => {
  //   const header = document.getElementById("myHeader");
  //   const sticky = header.offsetTop + 64;
  //   const scrollCallBack = window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > sticky) {
  //       header.classList.add("sticky");
      
  //     } else {
  //       header.classList.remove("sticky");
       
  //     }
  //   });
  //   console.log(sticky)
  //   return () => {
  //     window.removeEventListener("scroll", scrollCallBack);
  //   };
  // }, []);

  const labels = [
    {
      id: 'fee_type',
      type: 'Fee Type',
      name: 'Fee Type'
    },
    {
      id: 'fee_structure',
      type: 'Fee Structure',
      name: 'Fee Structure'
    },
  ];

  const Drawer = window.localStorage.getItem('drawer')

  const [height, setHeight] = useState(0);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);
  console.log(height)


  const [d ,setD] = useState(Drawer)
  window.addEventListener('storage', () => {
    // When local storage changes, dump the list to
    // the console.
    setD(window.localStorage.getItem('drawer'))
   
    console.log(JSON.parse(window.localStorage.getItem('drawer')));
  });

 
console.log(d)
  
  return (
    <Page  title="Applications">
    
      
{/*  <div id="myHeader" className="header">
  <Typography variant="h3" color="textPrimary">
    {headerText}
    </Typography>
  </div>
<Box mt = {2} />
  <Divider /> */}
    
    <Box className = {d === 'true' ? (classes.sticky) : (classes.sticky1)} ref={measuredRef}>
        <Header />
        <Box mt = {1}>
        <Divider />
      </Box> 
      </Box>
   
     

     <Box  marginTop = {"47px"}>
     
       <Box height="100%" display="flex" flexDirection="column"   minWidth = {256}  position= "fixed" style = {{borderColor : "#e0e0e0"}} borderRight={1}>
       <PerfectScrollbar options={{ suppressScrollX: true }}>
        <List disablePadding>
          {labels.map((label) => (
            <LabelItem 
              key={label.id}
              label={label}
            />
          ))}
        </List>
        </PerfectScrollbar>
      </Box>
    
     
      
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
   
      </Box>
   
    </Page>
  );
};

Applications.propTypes = {
  children: PropTypes.node
};
export default Applications;
