import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Icon } from '@iconify/react';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  root1: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

const LoadingScreen = () => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);
  const fullScreenHeight = window.innerHeight - 64;

  return (
    <div className={classes.root}>
       <Box
            justifyContent="center"
            align="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            height={`${fullScreenHeight - 64}px`}
          >
            <Box
              justifyContent="center"
              align="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Icon
                icon="eos-icons:bubble-loading"
                style={{ fontSize: '60px' }}
              />
            </Box>
          </Box>

    </div>
    // <div className={classes.root}>
    //   <Box>
    //     <LinearProgress />
    //   </Box>
    // </div>
  );
};

export default LoadingScreen;
