import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
require('dotenv').config();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  return (
      <Formik
        initialValues={{
       
          submit: null
        }}
      
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
          

            history.push('/app/fee/dashboard')
            window.location.reload(false)
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if(err.response){
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
              setSubmitting(false);
            }
          }else{
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: 'Network Error' });
              setSubmitting(false);
            }
          }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >{console.log(process.env.REACT_APP_ENV, process.env)}
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
        
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Release Version
            {' '}
            {APP_VERSION}  {process.env.REACT_APP_ENV}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
       
        <Button
          color="secondary"
          // component={RouterLink}
          // to="app/fee/home"
          type = "submit"
          variant="contained"
          size="small"
        >
          LOG IN
        </Button>
      </Toolbar>
    </AppBar>
    </form>
      )}
    </Formik>
  );
};


TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
