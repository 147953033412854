import React from 'react';
import {
  Box,
  makeStyles,
  List,
  ListSubheader,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LabelItem from './NavItem';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CastForEducationOutlined from '@material-ui/icons/CastForEducationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Search from 'src/layouts/DashboardLayout/TopBar/Search';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
const fullScreenHeight = window.innerWidth - 512;
const fullScreenHeight1 = window.innerWidth - 256;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    },
  wrapper: {
    display: 'flex',
    flexGrow: 1, 
    paddingLeft : 256,
    scrollBehavior: 'smooth',

  },
  sidebar: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64, 
    bottom: 0, 
    left: 256, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
   
  },
  sidebarN: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    position: 'fixed', 
    top: 64, 
    bottom: 0, 
    left: 256, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
   
  },
  sidebar1: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64,
    bottom: 0, 
    left: 0, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
  },
  sidebar1N: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    position: 'fixed', 
    top: 64,
    bottom: 0, 
    left: 0, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
  },
  content: {
    flexGrow: 1,
    scrollBehavior: 'smooth',
    maxWidth:fullScreenHeight

  },
  content1: {
    flexGrow: 1,
    scrollBehavior: 'smooth',
    maxWidth:fullScreenHeight1

  },
}));

const Applications = ({ children }) => {
  const classes = useStyles();
const { open, setOpen } = useDashboardContext();

  const Users = {
    // subheader: 'Admission Settings Panel',
    items: [
      {
        title: 'GENERAL SETTINGS',
        href: '/app/admin/settings/GeneralSettings',
        icon: 'fluent:settings-24-regular',
        items: [
          {
            title: 'Personal',
            href: '/app/admin/settings/GeneralSettings/Personal',
            // icon: 'ic:outline-assignment-turned-in'
          },
          {
            title: 'Institution',
            href: '/app/admin/settings/GeneralSettings/Company',
            // icon: 'ri:building-line'
          }
        ]
      },
      {
        title: 'Academic Settings',
        href: '/app/admin/settings/Academic',
        icon: 'mynaui:academic-hat',
        items: [
          // {
          //   title: 'Programs',
          //   href: '/app/admin/settings/programs',
          // },
          // {
          //   title: 'Quota',
          //   href: '/app/admin/settings/quota',
          // },
          {
            title: 'Stream',
            href: '/app/admin/settings/Academic/stream',
          },
          {
            title: 'Batch',
            href: '/app/admin/settings/Academic/batch',
          },
          {
            title: 'Academic Year',
            href: '/app/admin/settings/Academic/academicyear',
          },
        ]
      },
      {
        title: 'Students Onboarding',
        href: '/app/admin/settings/onboarding',
        icon: 'bi:person',
        items: [
          {
            title: 'Current Students',
            href: '/app/admin/settings/onboarding/students/live',
            //icon: 'ic:outline-assignment-turned-in'
          },
          {
            title: 'Previous Students' ,
            href: '/app/admin/settings/onboarding/previous_students/completed',
            //icon: 'ic:outline-assignment-turned-in'
          }
        ]
      },
       {
        title: 'Admission Settings',
        href: '/app/admin/settings/admission',
        icon: 'fluent:settings-24-regular',
        items: [
         
          {
            title: 'Student Forms',
            href: '/app/admin/settings/admission/forms',
            //icon: 'ic:round-list'
          },
          {
            title: 'Certificates',
            href: '/app/admin/settings/admission/enabledsoon/Certificates',
            //icon: 'material-symbols:description-outline'
          },
          {
            title: 'Promote Students',
            href: '/app/admin/settings/admission/promote_students',
            //icon: 'material-symbols:supervisor-account-outline'
          }
        ]
      },
      {
        title: ' Configure Fee',
        href: '/app/fee/settings/fee_settings',
        icon: 'carbon:tools',
        items: [
          {
            title: 'Accounts',
            href: '/app/fee/settings/fee_settings/accounts',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Category',
            href: '/app/fee/settings/fee_settings/fee_categories',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Type',
            href: '/app/fee/settings/fee_settings',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Structure',
            href: '/app/fee/settings/fee_settings/fee_structure',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Schedule',
            href: '/app/fee/settings/fee_settings/feeschedule',
            // icon: 'iconamoon:settings'
          }
        ]
      },
      {
        title: 'Template Settings',
        href: '/app/admin/settings/email',
        icon: 'iconoir:send-diagonal',
        items: [
         
          {
            title: 'Email',
            href: '/app/admin/settings/email/Email',
            //icon: 'ic:round-list'
          },
          {
            title: 'WhatsApp',
            href: '/app/admin/settings/email/enabledsoon/WhatsApp',
            //icon: 'ic:round-list'
          },
          {
            title: 'SMS',
            href: '/app/admin/settings/email/enabledsoon/SMS',
            //icon: 'ic:round-list'
          },
          {
            title: 'Label',
            href: '/app/admin/settings/email/enabledsoon/Label',
            //icon: 'ic:round-list'
          },
          
        ]
      },
      {
        title: 'User Management',
        href: '/app/admin/settings/user',
        icon: 'iconoir:profile-circle',
        items: [
          {
            title: 'User Groups',
            href: '/app/admin/settings/user/enabledsoon/User Group',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Users',
            href: '/app/admin/settings/user/user_management',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Data Access',
            href: '/app/admin/settings/user/enabledsoon/Data Access',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Roles & Permissions',
            href: '/app/admin/settings/user/enabledsoon/Roles & Permissions',
            //icon: 'ri:swap-line'
          },
         
        ]
      },
      {
        title: 'Data Management',
        href: '/app/admin/settings/data',
        icon: 'gala:data',
        items: [
          {
            title: 'Import Data',
            href: '/app/admin/settings/data/enabledsoon/Import Data',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Export Data',
            href: '/app/admin/settings/data/enabledsoon/Export Data',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Backup Data',
            href: '/app/admin/settings/data/enabledsoon/Backup Data',
            //icon: 'ri:swap-line'
          },
         
         
        ]
      },
    
     
      {
        title: 'Integration',
        href: '/app/admin/settings/Integration',
        icon: 'bx:buildings',
        
      },
      {
        title: 'Compliances',
        href: '/app/admin/settings/Compliances/enabledsoon/Compliances',
        icon: 'grommet-icons:compliance',
        
      },
     
      // {
      //   title: 'Academic',
      //   href : '/app/admin/user_management',
      //   icon: SwapVerticalCircleOutlinedIcon,
  
      // },
  
      // {
      //   title: 'Quick Admission',
      //   href: '/app/admin/settings/quick_admission',
      //   icon: AssignmentTurnedInOutlinedIcon
      // },
    ]
  };

  const UsersN = {
    // subheader: 'Admission Settings Panel',
    items: [
    
     
          {
            title: 'Accounts',
            href: '/app/fee/settings/fee_settings/accounts',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Category',
            href: '/app/fee/settings/fee_settings/fee_categories',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Type',
            href: '/app/fee/settings/fee_settings',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Structure',
            href: '/app/fee/settings/fee_settings/fee_structure',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Schedule',
            href: '/app/fee/settings/fee_settings/feeschedule',
            // icon: 'iconamoon:settings'
          }
      
    
      // {
      //   title: 'Academic',
      //   href : '/app/admin/user_management',
      //   icon: SwapVerticalCircleOutlinedIcon,
  
      // },
  
      // {
      //   title: 'Quick Admission',
      //   href: '/app/admin/settings/quick_admission',
      //   icon: AssignmentTurnedInOutlinedIcon
      // },
    ]
  };
  let history = useHistory();

  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <LabelItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
            
          })}
        </LabelItem>
      );
    } else {
      acc.push(
        <LabelItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  }

  const location = useLocation();

  const content = (
         <Box  px={2} pb ={2}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>

  );
  const contentN = (
   <Box px={2} py={1}>
    <Box display={'flex'} mb={1}  alignItems={'center'} style={{cursor:'pointer'}} onClick={()=>history.push('/app/fee/schedule')}>
      <Icon icon={'material-symbols:arrow-back-ios'} fontSize={'17px'}/>
      <Box mr={0.8}/>
      <Typography variant='body1' color='textPrimary' style={{fontWeight:500}}>
        Back to main menu
      </Typography>
    </Box>
    <Box  px={2} pb ={2}>
     <List
       key={UsersN.subheader}
       subheader={
         <ListSubheader disableGutters disableSticky>
           {UsersN.subheader}
         </ListSubheader>
       }
     >
       {renderNavItems({
         items: UsersN.items,
         pathname: location.pathname
       })}
     </List>
   </Box>
   
   
   </Box>

);
  return (
    <div className={classes.root}>
    {/* <Box className={open ? window.location.pathname.includes('fee_settings')? classes.sidebarN : classes.sidebar : window.location.pathname.includes('fee_settings')? classes.sidebar1N:classes.sidebar1}> */}
    <Box className={open ? classes.sidebar : classes.sidebar1}>

      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        {/* <List>{window.location.pathname.includes('fee_settings')?contentN:content}</List> */}
        <List>{content}</List>

      {/* </PerfectScrollbar> */}
    </Box>
    <Box className={classes.wrapper}>
      <Box className={open?classes.content:classes.content1} >
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        {children}
        {/* </PerfectScrollbar> */}
        </Box>
    </Box>
  </div>
  );
};

Applications.propTypes = {
  children: PropTypes.node
};
export default Applications;
